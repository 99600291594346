<template>
  <div>
    <DataTable
      v-if="Object.keys(data).length > 0"
      :headers="headers"
      :items="data"
      :preloader="fetchinData"
      :watch="true"
      @tableEvent="emittedTableEvent"
    ></DataTable>

    <v-dialog v-model="exportDialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark color="purple" v-bind="attrs" v-on="on" class="my-4">
          <v-icon left> mdi-download </v-icon>
          Export
        </v-btn>
      </template>

      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="exportDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("exportLeads") }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <ExportLeads
            :campaignId="campaign.id"
            @closeDialog="exportDialog = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>Lead Detail</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <LeadDetail
            ref="lead_detail"
            :id="leadId"
            :campaigns_id="campaign.id"
            :key="componentKey"
            @orderDeleted="orderDeleted()"
          ></LeadDetail>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)
import DataTable from "@/components/ui/DataTable";
import LeadDetail from "@/components/lead/manage/LeadDetail";
import ExportLeads from "@/components/campaigns/manage/ExportLeads";

export default {
  name: "CampaignLeadsList",

  components: {
    DataTable,
    LeadDetail,
    ExportLeads,
  },

  data() {
    return {
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      leadId: null,
      leadData: [],
      participationData: [],
      pageTitle: this.$t("Leads"),
      headers: [
        { text: "", value: "trafficLightIcon" },
        { text: this.$t("date"), value: "created" },
        { text: this.$t("salutation"), value: "gender" },
        { text: this.$t("firstName"), value: "firstName" },
        { text: this.$t("lastName"), value: "lastName" },
        { text: this.$t("email"), value: "email" },
        { text: this.$t("adSource"), value: "adSource" },
        { text: this.$t("Contacted"), value: "contactedIcon" },
        { text: this.$t("Scheduled"), value: "scheduledIcon" },
        { text: this.regularOrPayCampaign(), value: "begunIcon" },
        { text: this.$t("Lost"), value: "lostIcon" },
        { text: "", value: "actions", sortable: false },
      ],
      dialog: false,
      exportDialog: false,
      componentKey: 0,
    };
  },

  props: ["data", "hasPay", "campaign"],

  methods: {
    orderDeleted() {
      this.$emit("orderDeleted", true);
    },

    regularOrPayCampaign() {
      // decide what word is used for "redeemed". "Redeemed" or "Bought"
      // This based on campaign type
      if (this.hasPay === "1") {
        return this.$t("Bought");
      } else {
        return this.$t("begun");
      }
    },

    emittedTableEvent(obj) {
      this.leadId = obj.itemId;
      this.dialog = true;
      this.componentKey += 1; // forces the component (here LeadDetail) to re-render -> https://michaelnthiessen.com/force-re-render/
    },
  },

  computed: {
    //
  },

  /* all requests by mixin */
  mixins: [apiRequest],
};
</script>
